<template>
  <li class="social-media-item columns small-12 medium-6 xlarge-3 vertical-gutters">
    <a class="block no-anchor-underline"
      :class="{no_image: !this.item.image_url}"
      :href="item.link"
      :style="style">
      <div class="social-media-icon">
        <slot></slot>
      </div>
      <div class="social-item-inner">
        <p v-if="!this.item.image_url" class="social-title">{{ truncatedTitle }}</p>
        <span class="social-subtitle">{{ item.subtitle }}</span>
      </div>
    </a>
  </li>
</template>

<script>

import { truncate } from 'lodash'

const truncateObject = {
  length: 100,
  separator: ' '
}

export default {
  props: {
    item: Object
  },
  computed: {
    truncatedTitle: function () {
      return truncate(this.item.title, truncateObject)
    },
    style: function () {
      return `background-image: url(${this.item.image_url});`
    }
  }
  // computed: {
  // }
}
</script>
