<template>
  <div :class="{active: isActive}" class="event-list-filter">
    <div @click="toggleList" class="event-list-filter-toggle">
      <a>Filter</a>
      <icon-arrow-slim class="arrow-slim" :color="color" :transform="transform"/>
    </div>
    <ul class="event-list-filter-list">
      <filter-item v-on:on-change="hideList" :value="'Alle'" />
      <filter-item v-on:on-change="hideList" :value="category" v-for="(category, key) in categories" :key="key"/>
    </ul>
  </div>
</template>

<script>
import FilterItem from './FilterItem.vue'

export default {
  data: function () {
    return {
      isActive: false
    }
  },
  props: {
    list: Array
  },
  components: {
    FilterItem
  },
  methods: {
    toggleList: function () {
      this.isActive = !this.isActive
    },
    hideList: function () {
      this.isActive = false
    }
  },
  computed: {
    transform: function () {
      return this.isActive ? 'rotateX(180deg)' : 'rotateX(0)'
    },
    color: function () {
      return this.isActive ? '#fff' : '#000'
    },
    categories: function () {
      return this.$store.state.categories
    }
  },
  beforeCreate: function () {
    fetch(`/${this.$store.state.django.settings.LANGUAGE_CODE}/api/category/`)
      .then(response => response.json())
      .then(data => this.$store.commit('setCategories', data.map(item => item.title)))
  }
}

</script>
