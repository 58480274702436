<template>
  <li>
    <div class="event-item" v-bind:class="{ cancelled: item.status == 'cancelled' }">
      <div class="event-item-column event-fulldate-column">
        <div class="date-fulldate">{{ item.date_weekday }} {{ item.date_short }}</div>
        <div class="date-time">{{ item.date_time }}</div>
        <component
          :is="item.location_link ? 'a' : 'span'"
          v-bind="location"
          class="event-location"
        >{{ item.location }}</component>
      </div>
      <div class="event-item-column event-head-column">
        <a class="event-head-wrapper" :href="item.url">
          <div v-if="item.overline" class="event-item-category">
            {{ item.overline }}
          </div>
          <div class="event-item-headline" >{{ item.title }}</div>
          <div class="event-item-artist-list">
            <p>{{ item.description }}</p>
          </div>
        </a>
      </div>
      <div class="event-item-column event-location-column" v-bind:class="{isLivestream:item.goplus_url}">

        <div class="event-ticket-status" v-if="item.is_future && item.is_abo_presale_planned">{{ translations.abo_begin }} {{ formatDate(item.abo_sale_from) }}</div>
        <a class="event-ticket-btn" v-else-if="item.is_future && item.abo_ticket_link" :href="item.abo_ticket_link">Tickets für <br> Abo-Kunden</a>

        <div class="event-ticket-status" v-if="item.is_future && item.is_presale_planned">{{ translations.regular_begin }} {{ formatDate(item.sale_from) }}</div>
        <div class="event-ticket-btn disabled" v-else-if="item.is_future && item.is_cancelled">{{ translations.cancelled }}</div>
        <div class="event-ticket-btn disabled" v-else-if="item.is_future && item.is_soldout">{{ translations.soldout }}</div>
        <div class="event-ticket-status" v-else-if="item.is_future && item.is_free">{{ translations.free }}</div>
        <a class="event-ticket-btn" v-else-if="item.is_future && item.is_on_sale && item.full_ticket_link" :href="item.full_ticket_link">Tickets</a>
        <a class="event-ticket-btn" v-else-if="item.is_future && item.full_ticket_link" :href="item.full_ticket_link">Tickets</a>
        <a :href="item.goplus_url">
          <div v-if="item.goplus_url" class="livestream">
            <div class="logo-livestream">
              <img src="/static/images/svg/src/goplus.svg" alt="Guerzenich Orchestra plus">
            </div>
            <div class="livestream-info">
              <span class="livestream-info-title">Livestream</span>
              <!-- <span class="livestream-info-date">{{item.goplus_date_date}} {{item.goplus_date_time}}</span> -->
            </div>
          </div>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    item: {
      id: Number,
      title: String,
      description: String,
      date: String,
      category: String,
      goplus_date: String,
      url: [String],
      location: String,
      location_link: String
    }
  },
  methods: {
    formatDate (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    }
  },
  computed: {
    translations: function () {
      return this.$store.state.django.settings.TRANSLATIONS.events
    },
    location () {
      return this.item.location_link ? {
        href: this.item.location_link,
        target: '_blank',
        rel: 'noopener noreferrer'
      } : {}
    }
  }
}

</script>
