<template>
  <icon-wrapper :style="wrapperStyle">
    <svg viewBox="0 0 59.3 52.8">
    <path :style="pathStyle" d="M59.3,52.8H0v-48h59.3V52.8z M3.7,49.2h52V8.5h-52V49.2z"/>
    <rect :style="pathStyle" x="1.8" y="17.7" width="55.7" height="3.7"/>
    <rect :style="pathStyle" x="11" y="0.1" width="3.7" height="13.2"/>
    <rect :style="pathStyle" x="44.4" y="0.1" width="3.7" height="13.2"/>
    <g>
      <path :style="pathStyle" d="M22.4,32h-3.6v-2c1,0,1.8-0.1,2.4-0.3c0.5-0.2,0.9-0.5,1.1-0.9c0.2-0.4,0.3-1,0.3-1.8h2.1v17.4h-2.3V32z"/>
      <path :style="pathStyle" d="M30.7,44.1c-0.9-0.4-1.6-1-2.1-1.8c-0.5-0.8-0.8-1.7-0.8-2.7c0-1.1,0.3-2,0.8-2.7c0.5-0.7,1.3-1.2,2.3-1.5
        c-0.9-0.3-1.6-0.8-2.1-1.5c-0.5-0.7-0.8-1.6-0.8-2.6c0-0.9,0.2-1.7,0.7-2.4c0.5-0.7,1.2-1.2,2.1-1.6c0.9-0.4,1.9-0.6,3-0.6
        c1.1,0,2.2,0.2,3,0.6c0.9,0.4,1.6,0.9,2.1,1.6c0.5,0.7,0.7,1.5,0.7,2.4c0,1-0.3,1.8-0.8,2.6c-0.5,0.7-1.2,1.2-2.1,1.5
        c1,0.3,1.7,0.8,2.3,1.5c0.5,0.7,0.8,1.6,0.8,2.7c0,1-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2.1,1.8c-0.9,0.4-2,0.6-3.2,0.6
        C32.7,44.8,31.6,44.5,30.7,44.1z M36.6,41.9c0.7-0.6,1-1.4,1-2.3c0-1-0.3-1.7-1-2.3c-0.7-0.6-1.6-0.9-2.7-0.9c-1.1,0-2,0.3-2.7,0.9
        s-1,1.4-1,2.3c0,1,0.3,1.8,1,2.3c0.7,0.6,1.6,0.9,2.7,0.9C35,42.8,35.9,42.5,36.6,41.9z M36.4,33.7c0.6-0.5,0.9-1.2,0.9-2.1
        c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.5-1.5-0.8-2.5-0.8c-1.1,0-1.9,0.3-2.5,0.8c-0.6,0.5-0.9,1.2-0.9,2.1c0,0.9,0.3,1.6,1,2.1
        c0.6,0.5,1.5,0.8,2.5,0.8C35,34.5,35.8,34.3,36.4,33.7z"/>
    </g>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
