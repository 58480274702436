<template>
  <div class="event-list-wrapper archive">
    <div class="row actions">
      <div class="actions-inner double-gutters small-12">
        <h1 class="archive-headline">Archiv</h1>
        <event-search :class="{active: isSearchActive}" v-on:change="toggleSearch"/>
        <event-search-toggle v-on:change="toggleSearch"/>
        <event-filter :list="list"/>
      </div>
    </div>
    <div class="row">
      <div class="double-gutters double-gutters-collapse-medium-down small-12">
        <event-list :list="list"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventList from './components/EventList.vue'
import EventSearchToggle from './components/EventSearchToggle.vue'
import EventFilter from './components/EventFilter.vue'
import EventSearch from './components/EventSearch.vue'

export default {
  components: {
    EventList,
    EventSearchToggle,
    EventFilter,
    EventSearch
  },
  data: function () {
    return {
      isSearchActive: false
    }
  },
  methods: {
    toggleSearch: function () {
      this.isSearchActive = !this.isSearchActive
    }
  },
  computed: {
    list: function () {
      return this.$store.state.eventList
    }
  },
  beforeCreate: function () {
    this.$store.dispatch('getArchiveAsync')
    this.$store.commit('disableDateFilter')
  }
}
</script>
