<template>
  <li v-on:click="filter" :class="{active: isActive}" class="element filter-item item">
    <a>
      {{value}}
    </a>
  </li>
</template>

<script>

export default {
  props: {
    filterType: String,
    value: String
  },
  methods: {
    filter: function () {
      this.$store.commit('setCategoryFilter', this.value)
      this.$emit('on-change')
    }
  },
  computed: {
    isActive: function () {
      return this.$store.state.activeFilters.category === this.value
    }
  }
}
</script>
