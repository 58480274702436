<template>
  <icon-wrapper :style="wrapperStyle">
    <svg x="0px" y="0px" viewBox="0 0 30 16.5" style="enable-background:new 0 0 30 16.5;">
      <g>
        <path :style="pathStyle" d="M15,10.9L30,0v5.8L15,16.5L0,5.8V0L15,10.9z"/>
      </g>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    transform: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: this.transform
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
