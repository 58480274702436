<template>
  <div v-on:click="toggle" :class="activeClass" class="checkbox">
    <input :name="name" type="checkbox">
    <label :for="name">{{ name }}</label>
    <icon-checked :color="color" :class="activeClass" class="checkbox-icon-checked"></icon-checked>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isActive: false
    }
  },
  props: {
    name: String
  },
  methods: {
    toggle: function () {
      this.isActive = !this.isActive
      this.$emit('change')
    }
  },
  computed: {
    activeClass: function () {
      return this.isActive ? 'active' : ''
    },
    color: function () {
      return this.isActive ? '#000' : '#fff'
    },
    width: function () {
      return 17
    }
  }
}
</script>
