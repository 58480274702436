<template>
  <div class="svg-icon-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    change: function () {
      this.$emit('change')
    }
  }
}
</script>
