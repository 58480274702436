<template>
  <div class="event-list-wrapper">
    <div class="row actions">
      <event-calendar/>
      <event-search-toggle v-on:change="toggleSearch"/>
      <div class="actions-inner double-gutters small-12">
        <event-search :class="{active: isSearchActive}" v-on:change="toggleSearch"/>
        <event-filter :list="list"/>
        <a :href="linkArchive" v-if="isSearchActive" class="archive-link">zum Archiv</a>
      </div>
    </div>
    <div class="row">
      <div class="double-gutters double-gutters-collapse-medium-down small-12">
        <event-list :list="list"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventList from './components/EventList.vue'
import EventSearchToggle from './components/EventSearchToggle.vue'
import EventFilter from './components/EventFilter.vue'
import EventCalendar from './components/EventCalendar.vue'
import EventSearch from './components/EventSearch.vue'
// import mock from './mocks/event-list-2'

export default {
  components: {
    EventList,
    EventSearchToggle,
    EventFilter,
    EventCalendar,
    EventSearch
  },
  data: function () {
    return {
      isSearchActive: false
      // mock
      // mockData: mock
    }
  },
  methods: {
    toggleSearch: function () {
      this.isSearchActive = !this.isSearchActive
    }
  },
  computed: {
    list: function () {
      // mock
      // return this.mockData
      return this.$store.state.eventList
    },
    linkArchive: function () {
      return this.$store.state.django.settings.URL.archive
    }
  },
  beforeCreate: function () {
    this.$store.dispatch('getEventListAsync')
    this.$store.commit('enableDateFilter')
  }
}
</script>
