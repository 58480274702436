<template>
  <div class="row space-large-after">
    <div class="column double-gutters">
      <ul class="social-media row">
        <SocialItem v-if="facebook" :item="facebook">
          <icon-facebook class="social-media-facebook"/>
        </SocialItem>
        <SocialItem v-if="twitter" :item="twitter">
          <icon-twitter class="social-media-twitter"/>
        </SocialItem>
        <SocialItem v-if="instagram" :item="instagram">
          <icon-instagram class="social-media-instagram"/>
        </SocialItem>
        <SocialItem v-if="youtube" :item="youtube">
          <icon-youtube class="social-media-youtube"/>
        </SocialItem>
      </ul>
    </div>
  </div>
</template>

<script>
import SocialItem from './components/SocialItem.vue'

export default {
  components: {
    SocialItem
  },
  computed: {
    facebook: function () {
      return this.$store.state.socialMedia.facebook
    },
    twitter: function () {
      return this.$store.state.socialMedia.twitter
    },
    youtube: function () {
      return this.$store.state.socialMedia.youtube
    },
    instagram: function () {
      return this.$store.state.socialMedia.instagram
    }
  },
  beforeCreate: function () {
    this.$store.dispatch('getSocialMediaAsync')
  }
}
</script>
