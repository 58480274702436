export async function fetchAsync (endpoint) {
  // eslint-disable-next-line no-undef
  let response = await fetch(`/${DjangoApp.settings.LANGUAGE_CODE}/api/${endpoint}/`)
  let data = await response.json()
  return data
}

export function resetDateTime (date) {
  date.setUTCHours(0)
  date.setUTCMinutes(0)
  date.setUTCSeconds(0, 0)
  return date
}

export function monthDiff (dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}
