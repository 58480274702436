<template>
  <icon-wrapper :style="wrapperStyle">
    <svg viewBox="0 0 88 88" style="enable-background:new 0 0 88 88;" xml:space="preserve">
      <g id="Ebene_2">
        <path d="M44,88C19.7,88,0,68.3,0,44S19.7,0,44,0c10.7,0,20.9,3.9,28.9,10.9c2.2,1.9,4.2,4,6,6.3l-3.2,2.4c-1.6-2.1-3.4-4-5.4-5.8
          C63,7.5,53.7,4,44,4C21.9,4,4,21.9,4,44c0,22.1,17.9,40,40,40c22.1,0,40-17.9,40-40c0-0.6,0-1.3,0-1.9l4-0.2c0,0.7,0,1.4,0,2.1
          C88,68.3,68.3,88,44,88z"/>
        <path :style="pathStyle" d="M65,19"/>
        <polygon points="80,21 65,21 65,17 76,17 76,6 80,6"/>
      </g>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    transform: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: this.transform
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
