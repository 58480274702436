<template>
  <div class="event-list-search">
    <div
      v-on:click="toggleSearch"
      @mouseover="searchIconColor = '#ca983b'"
      @mouseleave="searchIconColor = '#000'"
      class="search-icon">
      <icon-search
        :color="searchIconColor"
      />
    </div>
    <div class="input-wrapper">
      <input ref="inputSearch" :placeholder="placeholder" v-model="searchValue" v-on:input="searchValueChanged" type="text">
      <button-primary class="button-search">suchen</button-primary>
    </div>
  </div>
</template>

<script>
import { truncate, debounce } from 'lodash'

const truncateObject = {
  length: 25,
  separator: ' '
}

export default {
  data: function () {
    return {
      placeholderString: 'Suchen Sie hier Veranstaltungen',
      isSearchActive: false,
      searchIconColor: '#000',
      searchValue: this.$store.state.activeFilters.search
    }
  },
  methods: {
    getTruncatedPlaceholder: function () {
      return truncate(this.placeholderString, {
        length: 25,
        separator: ' '
      })
    },
    openSearch: function () {
      this.isSearchActive = true
    },
    hideSearch: function () {
      this.isSearchActive = false
    },
    toggleSearch: function () {
      this.$refs.inputSearch.focus()
      this.$emit('change')
    },
    searchValueChanged: debounce(function (e) {
      this.$store.commit('setSearch', e.target.value)
    }, 500)
  },
  computed: {
    placeholder: function () {
      return window.innerWidth <= 1800 ? truncate(this.placeholderString, truncateObject) : this.placeholderString
    }
  }
}
</script>
