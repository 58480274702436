import { fetchAsync } from '@/helpers'

export default {
  async getEventListAsync ({ state, dispatch, commit }) {
    await dispatch('fetchEventList').then(() => {
      commit('setEventDateList', {
        dateFrom: state.eventList[0].date,
        dateTo: state.eventList[state.eventList.length - 1].date
      })
    })
  },
  async fetchEventList ({ commit }) {
    commit('setEventList', await fetchAsync('events'))
  },
  async getArchiveAsync ({ commit }) {
    commit('setEventList', await fetchAsync('archive'))
  },
  async getSocialMediaAsync ({ commit }) {
    commit('setSocialMedia', await fetchAsync('social'))
  }
}
