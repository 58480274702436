<template>
  <icon-wrapper :style="wrapperStyle">
    <svg x="0px" y="0px" viewBox="0 0 30 30">
      <path :style="pathStyle" d="M30.736,29.526 L30.174,30.084 L15.750,15.783 L1.326,30.084 L0.764,29.526 L15.188,15.226 L0.764,0.926 L1.326,0.368 L15.750,14.668 L30.174,0.368 L30.736,0.926 L16.312,15.226 L30.736,29.526 Z"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    transform: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: this.transform
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
