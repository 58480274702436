<template>
  <icon-wrapper v-on:click="change" :style="wrapperStyle">
    <svg x="0px" y="0px" viewBox="0 0 41.6 47.9" style="enable-background:new 0 0 41.6 47.9;">
    <path :style="pathStyle" d="M17.8,35.7C8,35.7,0,27.7,0,17.8C0,8,8,0,17.8,0s17.8,8,17.8,17.8C35.7,27.7,27.7,35.7,17.8,35.7z M17.8,3.1
      C9.7,3.1,3.1,9.7,3.1,17.8c0,8.1,6.6,14.7,14.7,14.7S32.6,26,32.6,17.8C32.6,9.7,26,3.1,17.8,3.1z"/>
    <rect :style="pathStyle" x="32.1" y="28" transform="matrix(0.7737 -0.6336 0.6336 0.7737 -16.891 30.0792)" width="3.1" height="21.4"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  methods: {
    change: function () {
      this.$emit('change')
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
