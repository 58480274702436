<template>
  <icon-wrapper :style="wrapperStyle">
    <svg viewBox="0 0 21 21">
      <path fill-rule="evenodd" :style="pathStyle"
      d="M20.995,18.943 L18.729,21.000 L10.497,12.671 L2.347,20.969 L0.102,18.918 L8.361,10.510 L0.005,2.057 L2.271,-0.000 L10.503,8.329 L18.653,0.031 L20.898,2.082 L12.639,10.490 L20.995,18.943 Z"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    transform: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: this.transform
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
