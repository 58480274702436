<template>
  <ul class="event-list">
    <loading v-if="loading && listFiltered.length > 0"/>
    <template v-if="!loading">
      <EventItem v-for="item in listFiltered" :key="item.id" :item="item" />
    </template>
  </ul>
</template>

<script>
import EventItem from './EventItem.vue'

function removeSpaces (string) {
  return string.replace(/\s+/g, ' ')
}

export default {
  components: {
    EventItem
  },
  methods: {
    hasCategory: function (item) {
      return item.categories.includes(this.filters.category)
    },
    isFutureEvent: function (item) {
      return new Date(item.date) >= this.filters.date
    },
    isInSearch: function (item) {
      return item.search_content ? item.search_content.toLowerCase().includes(removeSpaces(this.filters.search.toLowerCase())) : false
    }
  },
  props: {
    list: Array
  },
  computed: {
    loading () {
      return this.$store.state.loaders.eventList
    },
    filters () {
      return this.$store.state.activeFilters
    },
    allowedFilters () {
      return this.$store.state.allowedFilters
    },
    eventTranslations () {
      return this.$store.state.django.settings.TRANSLATIONS.events
    },
    listFiltered () {
      this.$store.commit('setEventListLoader', true)

      let result = this.list.slice()

      if (this.filters.category !== 'Alle' && this.allowedFilters.category) {
        result = result.filter(this.hasCategory)
      }

      if (this.allowedFilters.date) {
        result = result.filter(this.isFutureEvent)
      }

      result = result.filter(this.isInSearch)
      // eslint-disable-next-line
      setTimeout(() => {
        this.$store.commit('setEventListLoader', false)
      }, 500)

      return result
    }
  }
}
</script>
