<template>
  <icon-wrapper v-on:click="change" :style="wrapperStyle">
    <svg viewBox="0 0 17.1 37.2">
      <path :style="pathStyle" d="M16.4,18.6h-5.1v18.6H3.7V18.6H0V12h3.7V7.8c0-3,1.4-7.8,7.8-7.8l5.7,0v6.4H13
      c-0.7,0-1.6,0.3-1.6,1.8V12h5.8L16.4,18.6z M16.4,18.6"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  methods: {
    change: function () {
      this.$emit('change')
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
