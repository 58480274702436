<template>
    <div class="row align-center space-small-before space-small-after">
      <div class="loading-spin-animation loading-icon">
        <icon-loading/>
      </div>
    </div>
</template>

<script>
export default {}
</script>
