<template>
  <icon-wrapper v-on:click="change" :style="wrapperStyle">
    <svg viewBox="0 0 44.5 44.5">
      <path :style="pathStyle" d="M44.4,13.1c-0.1-2.4-0.5-4-1-5.4C42.8,6.2,42,5,40.8,3.7c-1.2-1.2-2.5-2-3.9-2.6
        c-1.4-0.6-3-0.9-5.4-1C29.1,0,28.3,0,22.3,0s-6.8,0-9.2,0.1c-2.4,0.1-4,0.5-5.4,1C6.2,1.7,5,2.5,3.7,3.7C2.5,5,1.7,6.2,1.2,7.7
        c-0.6,1.4-0.9,3-1,5.4C0,15.5,0,16.2,0,22.3s0,6.8,0.1,9.2c0.1,2.4,0.5,4,1,5.4c0.6,1.5,1.3,2.7,2.6,3.9c1.2,1.2,2.5,2,3.9,2.6
        c1.4,0.6,3,0.9,5.4,1c2.4,0.1,3.1,0.1,9.2,0.1s6.8,0,9.2-0.1c2.4-0.1,4-0.5,5.4-1c1.5-0.6,2.7-1.3,3.9-2.6c1.2-1.2,2-2.5,2.6-3.9
        c0.6-1.4,0.9-3,1-5.4c0.1-2.4,0.1-3.1,0.1-9.2S44.5,15.5,44.4,13.1z M40.4,31.3c-0.1,2.2-0.5,3.3-0.8,4.1c-0.4,1-0.9,1.8-1.7,2.6
        c-0.8,0.8-1.5,1.3-2.6,1.7c-0.8,0.3-2,0.7-4.1,0.8c-2.3,0.1-3.1,0.1-9,0.1s-6.6,0-9-0.1c-2.2-0.1-3.3-0.5-4.1-0.8
        c-1-0.4-1.8-0.9-2.6-1.7s-1.3-1.5-1.7-2.6c-0.3-0.8-0.7-2-0.8-4.1C4,28.9,4,28.2,4,22.3c0-5.9,0-6.6,0.1-9c0.1-2.2,0.5-3.3,0.8-4.1
        c0.4-1,0.9-1.8,1.7-2.6c0.8-0.8,1.5-1.3,2.6-1.7c0.8-0.3,2-0.7,4.1-0.8C15.6,4,16.3,4,22.3,4s6.6,0,9,0.1c2.2,0.1,3.3,0.5,4.1,0.8
        c1,0.4,1.8,0.9,2.6,1.7s1.3,1.5,1.7,2.6c0.3,0.8,0.7,2,0.8,4.1c0.1,2.3,0.1,3.1,0.1,9C40.5,28.2,40.5,28.9,40.4,31.3z M22.3,10.8
        c-6.3,0-11.4,5.1-11.4,11.4c0,6.3,5.1,11.4,11.4,11.4c6.3,0,11.4-5.1,11.4-11.4C33.7,15.9,28.6,10.8,22.3,10.8z M22.3,29.7
        c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4c4.1,0,7.4,3.3,7.4,7.4C29.7,26.4,26.4,29.7,22.3,29.7z M36.8,10.4
        c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7S36.8,8.9,36.8,10.4z"/>
      </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  methods: {
    change: function () {
      this.$emit('change')
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
