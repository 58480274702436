<template>
  <div v-on:click="toggle" :class="additionalClass" class="search-toggle">
    <icon-search v-if="!isActive"/>
    <icon-close v-if="isActive"/>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      isActive: false
    }
  },
  methods: {
    toggle: function () {
      this.isActive = !this.isActive
      this.$emit('change')
    }
  },
  computed: {
    additionalClass: function () {
      return this.isActive ? 'close-icon' : 'search-icon'
    }
  }
}
</script>
