import Vue from 'vue'
import EventListApp from './EventListApp.vue'
import ArchiveApp from './ArchiveApp.vue'
import SocialMedia from './SocialMedia.vue'
import store from './store'

import './global-register.js'

Vue.config.productionTip = false

const eventList = 'event-list'
const archive = 'archive'
const socialMedia = 'social-media'

if (document.getElementById(eventList)) {
  new Vue({
    store,
    render: h => h(EventListApp)
  }).$mount('#' + eventList)
}

if (document.getElementById(archive)) {
  new Vue({
    store,
    render: h => h(ArchiveApp)
  }).$mount('#' + archive)
}

if (document.getElementById(socialMedia)) {
  new Vue({
    store,
    render: h => h(SocialMedia)
  }).$mount('#' + socialMedia)
}
