<template>
  <icon-wrapper v-on:click="change" :style="wrapperStyle">
    <svg viewBox="0 0 42.2 34.3">
      <path :style="pathStyle" d="M42.2,4.1c-1.6,0.7-3.2,1.2-5,1.4c1.8-1.1,3.2-2.8,3.8-4.8c-1.7,1-3.5,1.7-5.5,2.1
        C34,1.1,31.7,0,29.2,0c-4.8,0-8.7,3.9-8.7,8.7c0,0.7,0.1,1.3,0.2,2C13.6,10.3,7.2,6.8,2.9,1.6C2.2,2.9,1.8,4.4,1.8,5.9
        c0,3,1.5,5.7,3.9,7.2c-1.4,0-2.8-0.4-3.9-1.1c0,0,0,0.1,0,0.1c0,4.2,3,7.7,6.9,8.5C7.9,20.9,7.2,21,6.4,21c-0.6,0-1.1-0.1-1.6-0.2
        c1.1,3.4,4.3,5.9,8.1,6c-3,2.3-6.7,3.7-10.8,3.7c-0.7,0-1.4,0-2.1-0.1c3.8,2.5,8.4,3.9,13.3,3.9c15.9,0,24.6-13.2,24.6-24.6
        c0-0.4,0-0.7,0-1.1C39.6,7.3,41.1,5.8,42.2,4.1L42.2,4.1z M42.2,4.1"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  methods: {
    change: function () {
      this.$emit('change')
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
