<template>
  <div class="calendar" :class="{active: isActive}">
    <div class="calendar-toggle-wrapper">
      <div class="calendar-toggle" @click="toggle">
        <span class="calendar-icon">
          <icon-calendar :color="color"/>
        </span>
        <div class="selected-month-wrapper">
          <span class="selected-month">{{ activeMonth }}</span>
          <span class="arrow-icon">
            <icon-arrow-slim class="arrow" :color="color" :transform="transform"/>
          </span>
        </div>
      </div>
    </div>
    <ul class="calendar-month-list">
      <li
        v-for="(date, key) in dateList"
        :key="key"
        @click.prevent="setDateFilter(date)"
      >
        <span v-if="isJanuary(date)" class="current-year">{{date.getFullYear()}}</span>
        <a href="#">
          {{ monthsMap[date.getMonth()] }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      isActive: false,
      monthsMap: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      calendarWrapperStyle: null
    }
  },
  methods: {
    isJanuary (date) {
      return date.getMonth() === 0
    },
    setDateFilter (date) {
      this.$store.commit('setDateFilter', date)
      this.isActive = false
    },
    toggle: function () {
      this.isActive = !this.isActive
    }
  },
  computed: {
    transform: function () {
      return this.isActive ? 'rotateX(180deg)' : 'rotateX(0deg)'
    },
    color: function () {
      return this.isActive ? '#fff' : '#000'
    },
    activeMonth: function () {
      return this.monthsMap[this.$store.state.activeFilters.date.getMonth()]
    },
    dateList: function () {
      return this.$store.state.dateList
    }
  }
}
</script>
