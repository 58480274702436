<template>
  <icon-wrapper v-on:click="change" :style="wrapperStyle">
    <svg viewBox="0 0 48.6 34.2" >
      <path :style="pathStyle" d="M19.3,23.4l0-13.6l13.1,6.8L19.3,23.4L19.3,23.4z M48.1,7.4c0,0-0.5-3.3-1.9-4.8
        c-1.8-1.9-3.9-1.9-4.9-2.1C34.5,0,24.3,0,24.3,0h0c0,0-10.2,0-17,0.5c-0.9,0.1-3,0.1-4.9,2.1C1,4,0.5,7.4,0.5,7.4S0,11.3,0,15.2v3.7
        c0,3.9,0.5,7.9,0.5,7.9s0.5,3.3,1.9,4.8c1.8,1.9,4.3,1.9,5.4,2.1c3.9,0.4,16.5,0.5,16.5,0.5s10.2,0,17-0.5c0.9-0.1,3-0.1,4.9-2.1
        c1.5-1.5,1.9-4.8,1.9-4.8s0.5-3.9,0.5-7.9v-3.7C48.6,11.3,48.1,7.4,48.1,7.4L48.1,7.4z"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  methods: {
    change: function () {
      this.$emit('change')
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: `rotate(${this.rotate}deg)`
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
