import Vue from 'vue'
import Vuex from 'vuex'
import mutations from '@/mutations/mutations'
import actions from '@/actions/actions'
import { resetDateTime } from '@/helpers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // eslint-disable-next-line no-undef
    django: DjangoApp,
    eventList: [],
    dateList: [],
    categories: [],
    socialMedia: {},
    areCookiesEnabled: false,
    loaders: {
      eventList: false,
      socialMedia: false
    },
    allowedFilters: {
      category: true,
      date: true
    },
    activeFilters: {
      search: '',
      category: 'Alle',
      date: resetDateTime(new Date(Date.now()))
    }
  },
  mutations: mutations,
  actions: actions
})
