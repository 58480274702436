import { resetDateTime, monthDiff } from '@/helpers'

export default {
  setSearch (state, payload) {
    state.activeFilters.search = payload
  },
  setCategories (state, payload) {
    state.categories = payload
  },
  setCategoryFilter (state, payload) {
    state.activeFilters.category = payload
  },
  setDateFilter (state, payload) {
    state.activeFilters.date = payload
  },
  togglePastEvents (state) {
    state.activeFilters.showPastEvents = !state.activeFilters.showPastEvents
  },
  setEventList (state, payload) {
    state.eventList = payload
  },
  setSocialMedia (state, payload) {
    state.socialMedia = payload
  },
  disableDateFilter (state) {
    state.allowedFilters.date = false
  },
  enableDateFilter (state) {
    state.allowedFilters.date = true
  },
  setEventListLoader (state, payload) {
    state.loaders.eventList = payload
  },
  enableCookies (state) {
    state.areCookiesEnabled = true
  },
  setEventDateList (state, payload) {
    let dateFrom = new Date(payload.dateFrom)
    let dateTo = new Date(payload.dateTo)
    let result = []

    for (let i = 0; i <= monthDiff(dateFrom, dateTo); i++) {
      let date = new Date(dateFrom)
      date.setDate(1)
      date.setMonth(date.getMonth() + i)
      result.push(resetDateTime(date))
    }

    state.dateList = result
  },
  initEventDateList (state) {

  }
}
