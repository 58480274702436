<template>
  <icon-wrapper :style="wrapperStyle">
    <svg x="0px" y="0px" viewBox="0 0 30 15">
      <path :style="pathStyle" d="m29.997,0.002l-9.72,0l-5.277,6.41l-5.278,-6.41l-9.718,0l13.736,14.997l2.521,0l13.736,-14.997z" fill="rgb(0, 0, 0)" fill-rule="evenodd"/>
    </svg>
  </icon-wrapper>
</template>

<script>
export default {
  props: {
    transform: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    wrapperStyle: function () {
      return {
        transform: this.transform
      }
    },
    pathStyle: function () {
      return {
        fill: this.color
      }
    }
  }
}
</script>
